<template>
  <div class="" v-if="signaPageInfo.signaPageShow">
    <!-- <signaTurePage @closePage="closePage" @getSignaPageInfo="getSignaPageInfo" /> -->
  </div>
  <div id="electronicsList" v-else>
    <van-nav-bar title="电子签收单" left-arrow @click-left="goLastPage" :border="false" />
    <div class="popupWrap">
      <div class="topTip">
        <p class="p1">服务签收单(电子版)</p>
        <p class="p2">请客户核实并且填写以下表格</p>
      </div>
      <SelectWrap theme="订单详情">
        <template #content>
          <van-cell-group>
            <van-cell title="订单编号" :value="orderInfo.order_id" />
            <van-cell title="客户姓名" :value="orderInfo.client_name" />
          </van-cell-group>
        </template>
      </SelectWrap>
      <SelectWrap theme="安装产品是否正常使用" isMust="true">
        <template #content>
          <van-radio-group v-model="isNormal" checked-color="#FF816C">
            <van-cell-group>
              <van-cell title="是" clickable @click="isNormal = '1',normalshow = false">
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
              <van-cell title="否" clickable @click="isNormal = '2', normalshow = true">
                <template #right-icon>
                  <van-radio name="2" />
                </template>
              </van-cell>
              <van-field v-model="notNormalReason" rows="2" autosize label="不正常说明" type="textarea" maxlength="50" placeholder="请输入" show-word-limit v-show="normalshow" />
            </van-cell-group>
          </van-radio-group>
        </template>
      </SelectWrap>
      <SelectWrap theme="安装产品是否存在刮痕、损伤" isMust="true">
        <template #content>
          <van-radio-group v-model="isWorn" checked-color="#FF816C">
            <van-cell-group>
              <van-cell title="是" clickable @click="isWorn = '2',wornShow=true">
                <template #right-icon>
                  <van-radio name="2" />
                </template>
              </van-cell>
              <van-field v-model="wornReason" rows="2" autosize label="划痕损伤说明" type="textarea" maxlength="50" placeholder="请输入" show-word-limit v-show="wornShow" />
              <van-cell title="否" clickable @click="isWorn = '1',wornShow=false">
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </template>
      </SelectWrap>
      <SelectWrap theme="完成情况" isMust="true">
        <template #content>
          <van-radio-group v-model="isFinish" checked-color="#FF816C">
            <van-cell-group>
              <van-cell title="已完成" clickable @click="isFinish = '1',finishShow = false, signaTrueShow=true">
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
              <van-cell title="未完成" clickable @click="isFinish = '2',finishShow=true,signaTrueShow=false">
                <template #right-icon>
                  <van-radio name="2" />
                </template>
              </van-cell>
              <van-field v-model="notFinishReason" rows="2" autosize label="未完成原因" type="textarea" maxlength="50" placeholder="请输入" show-word-limit v-if="finishShow" />
            </van-cell-group>
          </van-radio-group>
        </template>
      </SelectWrap>
      <SelectWrap theme="备注栏">
        <template #content>
          <van-field v-model="remarks" rows="2" autosize label="客户备注" type="textarea" maxlength="50" placeholder="请输入留言" show-word-limit />
        </template>
      </SelectWrap>
      <SelectWrap theme="服务满意度评分" isMust="true">
        <template #content>
          <div class="startWrap">
            <van-rate v-model="startValue" :count="5" size="3rem" color="#F8843E" />
          </div>
        </template>
      </SelectWrap>
      <SelectWrap theme="客户签字" v-show="signaTrueShow">
        <template #content>
          <div class="autographWrap" @click="goSignaturePage">
            <div class="autograph autographPic" v-if="signaPageInfo.resultInfo.data">
              <!-- <img :src="`${cdnHttp}${signaPageInfo.resultInfo.data.result.url}`" alt=""> -->
              <!-- <img :src="`${cdnHttp}${signaPageInfo.resultInfo.data.result.url}?imageView2/1/format/webp/w/110/h/100/q/90`" alt=""> -->
              <van-image width="5rem" height="10rem" fit="contain" :src="`${cdnHttp}${signaPageInfo.resultInfo.data.result.url}`" />
            </div>
            <div class="autograph" v-else>
              <p>客户签字(点击)</p>
              <p><svg t="1604629162334" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2813" width="30" height="30">
                  <path d="M872.064 847.104H205.312c-14.08 0-25.6-11.52-25.6-25.6s11.52-25.6 25.6-25.6h666.752c14.08 0 25.6 11.52 25.6 25.6s-11.52 25.6-25.6 25.6zM352.64 693.632c-1.28 0-2.432-0.128-3.712-0.256L220.288 674.56c-11.264-1.664-19.968-10.368-21.632-21.632l-18.816-128.512c-1.152-8.064 1.536-16.128 7.168-21.76L576.512 113.28c9.984-9.984 26.24-9.984 36.224 0l147.328 147.328a25.3824 25.3824 0 0 1 0 36.096L370.688 686.08a25.6 25.6 0 0 1-18.048 7.552z m-105.984-66.944l97.024 14.208 362.112-362.112-111.104-111.104-362.24 361.984 14.208 97.024z" p-id="2814"></path>
                </svg></p>
            </div>

          </div>
        </template>
      </SelectWrap>

      <van-button type="primary" block color="linear-gradient(to right, #ff6034, #ee0a24)" text="确定提交" @click="submitEleList">
      </van-button>
    </div>
    <!-- 签名 -->
  </div>

</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
import SelectWrap from "@/components/effect/SelectWrap.vue";
// import signaTurePage from "@/components/effect/signaTurePage.vue";

export default {
  data() {
    return {
      isNormal: "",
      normalshow: false,
      notNormalReason: "",
      isWorn: "",
      wornShow: false,
      wornReason: "",
      isFinish: "",
      finishShow: false,
      notFinishReason: "",
      warnReason: "",
      remarks: "",
      signaTrueShow: false,
      signaPageShow: false,
      startValue: -1,
      orderInfo: {},
      show: false,
      signaTrueImg: "",
      signaPageInfo: {
        resultInfo: {},
        signaPageShow: false,
      },
    };
  },
  components: {
    TopBar,
    SelectWrap,
    // signaTurePage,
  },
  created() {
    console.log(this.$route);
    this.getOrderinfo();
  },
  methods: {
    closePage(e) {
      this.signaPageShow = e;
    },
    getSignaPageInfo(el) {
      this.signaPageInfo = el;
      console.log(this.signaPageInfo);
    },
    goSignaturePage() {
      this.signaPageInfo.signaPageShow = true;
    },
    getOrderinfo() {
      this.$http
        .get(`/api/v2/worker/order/${this.$route.params.orderId}/order_id`)
        .then((res) => {
          console.log(res);
          this.orderInfo = res.data.reservation;
        });
    },
    submitEleList() {
      if (this.isNormal == "") {
        this.$toast("请选择产品是否正常");
      } else if (this.isNormal == 2 && this.notNormalReason == "") {
        this.$toast("请填写产品不正常说明");
      } else if (this.isWorn == "") {
        this.$toast("请选择商品是否存在划痕");
      } else if (this.isWorn == 2 && this.wornReason == "") {
        this.$toast("请填写划痕损伤原因");
      } else if (this.isFinish == "") {
        this.$toast("请填写完成情况");
      } else if (this.isFinish == 2 && this.notFinishReason == "") {
        this.$toast("请填写未完成原因");
      } else if (this.startValue == -1) {
        this.$toast("请填写满意度评分");
      } else if (
        this.isFinish == 1 &&
        this.signaPageInfo.resultInfo.data == undefined
      ) {
        this.$toast("请签字");
      } else {
        this.$http
          .post(`/api/v2/worker/order/${this.$route.params.orderId}/signfor`, {
            whether_intact: this.isNormal,
            not_intact_reason: this.notNormalReason,
            whether_damage: this.isWorn,
            damage_reason: this.wornReason,
            whether_done: this.isFinish,
            not_done_reason: this.notFinishReason,
            remarks: this.remarks,
            score: this.startValue,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$toast.success("提交成功");
              this.goSubPageR("finished_table", this.$route.params.orderId);
            }
          })
          .catch((err) => {
            this.$toast.fail(err.error);
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
#electronicsList {
  .popupWrap {
    .topTip {
      text-align: center;
      line-height: 3rem;
      position: relative;
      padding: 1rem 0;
      background: #fff;
      .p1 {
        font-size: 1.6rem;
        font-weight: 400;
      }
      .p2 {
        font-size: 1.4rem;
        color: #afafaf;
      }
    }
    .topTip::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background: -webkit-repeating-linear-gradient(
        135deg,
        #ff6c6c 0,
        #ff6c6c 20%,
        transparent 0,
        transparent 25%,
        #1989fa 0,
        #1989fa 45%,
        transparent 0,
        transparent 50%
      );
      background: repeating-linear-gradient(
        -45deg,
        #ff6c6c 0,
        #ff6c6c 20%,
        transparent 0,
        transparent 25%,
        #1989fa 0,
        #1989fa 45%,
        transparent 0,
        transparent 50%
      );
      background-size: 80px;
      content: "";
    }
    .startWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0 2rem 0;
    }
    .autographWrap {
      margin: 0 auto;
      width: 90%;
      padding: 1rem 0 2rem 0;
      .autograph {
        width: 100%;
        border: 1px solid #afafaf;
        padding: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 1.4rem;
        }
      }
    }
  }
}
</style>