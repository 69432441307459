<template>
  <div class="selectWrap">
    <p class="selectWrapTitle">{{theme}}<span v-if="isMust" class="isMust">必填</span>
      <span>
        <slot name="tipRight">
        </slot>
      </span>
    </p>
    <!-- <div class="van-hairline--bottom">
      
    </div> -->

    <div class="selectContent">
      <slot name="content">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    theme: "",
    isMust: false,
  },
};
</script>
<style lang="less" scoped>
.selectWrap {
  margin: 1.5rem 0;
  background: #fff;
  .selectWrapTitle {
    text-align: center;
    font-size: 1.5rem;
    padding: 1.2rem 0;
    span {
      font-size: 1.3rem;
      color: #b0b0b0;
      padding-left: 0.85rem;
      &.isMust {
        font-size: 1.2rem;
        color: red;
      }
    }
  }
  .selectContent {
    width: 100%;
    background: #fff;
  }
}
</style>